import React, { useContext, useEffect, useState } from 'react'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import IconPlus from '@veneer/core/dist/scripts/icons/icon_plus'
import IconTrash from '@veneer/core/dist/scripts/icons/icon_trash'
import TextBox from '@veneer/core/dist/scripts/text_box'
import PropTypes from 'prop-types'
import Button from '@veneer/core/dist/scripts/button'
import { Modal } from './shared-components/ModalDefault/index'
import {
  EmailItem,
  EmailItemContainer,
  FormInline,
  Header,
  HeaderUsers,
  CancelInfo,
  EmailInfo
} from './styles'
import {
  createErrorMessage,
  createSuccessMessage,
  getErrorCode
} from './createMessages'
import { useTranslator } from '../../hooks/useTranslator'
import {
  publishButtonEvent,
  publishModuleDisplayedEvent
} from '../../utils/analytics'
import { HTTP_STATUS_CODES } from '../../constants/httpStatusCodes'

export const EMAIL_REGEX = new RegExp( // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const INVITES_MAX_LENGTH = 50

const validateUser = (email) => EMAIL_REGEX.test(email)

const EmailInput = ({ addEmail, clear, disabled }) => {
  const t = useTranslator()
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)
  useEffect(() => {
    setEmail('')
    setError(null)
  }, [clear])
  const onClickAdd = () => {
    const { error, message } = addEmail(email)
    if (error) {
      setError(message)
    } else {
      setEmail('')
      setError(null)
    }
  }
  return (
    <TextBox
      label={t('hpxUsers.inviteOverlay.entryField')}
      id="user-email"
      data-testid="user-email"
      type="text"
      error={!!error}
      helperText={error}
      value={email}
      onChange={(value) => setEmail(value)}
      disabled={disabled}
      aria-label="enter user email"
      trailingIcon={
        <Button
          leadingIcon={<IconPlus />}
          disabled={disabled}
          appearance="ghost"
          onClick={onClickAdd}
          aria-label="add user to invite list"
        />
      }
    />
  )
}

const EmailToAdd = ({ email, onDelete, disabled }) => (
  <EmailItemContainer id={`${email}-container`}>
    <EmailItem
      id={`${email}-add`}
      key={email}
      readOnly
      value={email}
      aria-label="invite users list"
      trailingIcon={
        <Button
          data-udl-link-placement="manager-users-modal"
          data-udl-link-id="remove-invite-user"
          leadingIcon={<IconTrash />}
          appearance="ghost"
          disabled={disabled}
          onClick={() => {
            onDelete(email)
          }}
          aria-label="remove user from invited list"
        />
      }
    />
  </EmailItemContainer>
)

EmailToAdd.propTypes = {
  email: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

const callInviteAllUsers = async ({
  accountId,
  emails,
  onFinish,
  onSuccess,
  onError,
  inviteUser,
  getUpdatedUserData
}) => {
  let promises = emails.map(async (email) => {
    try {
      const response = await inviteUser({ accountId, email })
      if (response.status === HTTP_STATUS_CODES.SUCCESS.OK) {
        onSuccess(email)
      } else {
        onError(email, response)
      }
    } catch (error) {
      onError(email, error)
    }
    return email
  })

  await Promise.all(promises).then(() => {
    getUpdatedUserData()
    onFinish()
  })
}

const InviteUsers = ({
  show,
  users,
  onClose,
  onFinish,
  accountId,
  inviteUser,
  getUpdatedUserData,
  analytics
}) => {
  const [usersMailList, setUsersMailList] = useState({
    list: [],
    isSendingInvites: false,
    disableConfirm: true
  })
  const [trashDisabled, setTrashDisabled] = useState(false)
  const t = useTranslator()

  // CODE COMMENTED BELOW NEEDS TO BE REFACTORED

  const { addToast } = useToast()
  const openToast = (id, type, text) => {
    addToast({ id: `${id}-toast`, type, text })
  }

  const onCloseModal = () => {
    onClose()
    setUsersMailList({
      list: [],
      isSendingInvites: false,
      disableConfirm: true
    })
  }

  const handleCloseButton = () => {
    publishButtonEvent(
      analytics,
      'InviteUsers',
      'ControlButtonClicked',
      'Cancel'
    )

    onCloseModal()
  }

  const sendUserInvitations = (analytics) => {
    publishButtonEvent(
      analytics,
      'InviteUsers',
      'ControlButtonClicked',
      'Confirm',
      `numberOfUsers=${usersMailList.list.length}`
    )

    setUsersMailList({
      list: usersMailList.list,
      disableConfirm: false,
      isSendingInvites: true
    })

    setTrashDisabled(true)

    callInviteAllUsers({
      accountId,
      emails: usersMailList.list,
      onSuccess: (email) => {
        openToast(email, 'positive', t(createSuccessMessage(), { email }))
      },
      onFinish: () => {
        publishModuleDisplayedEvent(
          analytics,
          'InviteUsersSuccess',
          `numberOfUsers=${usersMailList.list.length}`
        )
        setUsersMailList({
          disableConfirm: true,
          isSendingInvites: false,
          list: []
        })
        onCloseModal()
        onFinish()
      },
      onError: (email, error) => {
        const errorMessage = createErrorMessage(getErrorCode(error))
        publishModuleDisplayedEvent(
          analytics,
          'InviteUsersError',
          `numberOfUsers=${usersMailList.list.length}&error=${errorMessage}`
        )
        openToast(email, 'negative', t(errorMessage, { email }))
      },
      inviteUser,
      getUpdatedUserData
    })
  }

  const addEmailToInviteList = (email) => {
    const emailExistsMessage = (address) => {
      const wasAddedBefore = users.some(
        (item) => item.email.toLowerCase() === address.toLowerCase()
      )

      const inTheList = usersMailList.list.some(
        (item) => item.toLowerCase() === address.toLowerCase()
      )

      const alreadyInvited = wasAddedBefore || inTheList

      if (alreadyInvited) {
        publishModuleDisplayedEvent(
          analytics,
          'InviteUsersError',
          `numberOfUsers=${usersMailList.list.length}&error=This email has already been invited`
        )
      }

      return alreadyInvited
        ? t('hpxUsers.inviteOverlay.alreadyInvitedError')
        : false
    }

    const listMaxReachedMessage = (list) =>
      list.length >= INVITES_MAX_LENGTH
        ? t('hpxUsers.inviteOverlay.invitesLimitError')
        : false

    const invalidEmailMessage = (email) =>
      !validateUser(email) ? t('hpxUsers.inviteOverlay.emailError') : false

    const error =
      invalidEmailMessage(email) ||
      emailExistsMessage(email) ||
      listMaxReachedMessage(usersMailList.list.concat(users))

    if (error) {
      return { error: true, message: error }
    } else {
      setTrashDisabled(false)
      setUsersMailList({
        ...usersMailList,
        list: [...usersMailList.list, email],
        disableConfirm: false
      })
      return { error: false }
    }
  }

  return (
    <Modal
      id="regular-modal"
      onClose={onCloseModal}
      show={show}
      title={t('hpxUsers.inviteOverlay.header')}
      dataTestId="invite-modal-up"
      footer={
        <CancelInfo className="vn-button-group--responsive">
          <Button
            id="buttonInfo"
            disabled={usersMailList.isSendingInvites}
            onClick={handleCloseButton}
            aria-label="cancel"
            data-testid="cancel-btn-up"
          >
            {t('hpxUsers.inviteOverlay.cancel')}
          </Button>
          <Button
            disabled={usersMailList.disableConfirm}
            loading={usersMailList.isSendingInvites}
            onClick={() => sendUserInvitations(analytics)}
            aria-label="confirm"
            data-testid="confirm-btn-up"
          >
            {t('hpxUsers.inviteOverlay.confirm')}
          </Button>
        </CancelInfo>
      }
    >
      <Header className="body">{t('hpxUsers.inviteOverlay.copy')}</Header>
      <EmailInfo>
        <FormInline data-testid="invite-users" className="body">
          <EmailInput
            clear={show}
            addEmail={addEmailToInviteList}
            disabled={usersMailList.isSendingInvites}
          />
        </FormInline>
      </EmailInfo>
      <div id="users-list">
        {usersMailList.list.length > 0 && (
          <HeaderUsers className="body-large">
            {t('hpxUsers.inviteOverlay.label')}
          </HeaderUsers>
        )}
        {usersMailList.list.map((email) => (
          <EmailToAdd
            key={email}
            email={email}
            disabled={trashDisabled}
            onDelete={(emailToDelete) => {
              setUsersMailList({
                ...usersMailList,
                list: usersMailList.list.filter(
                  (item) => item !== emailToDelete
                ),
                disableConfirm: usersMailList.list.length - 1 === 0
              })
            }}
          />
        ))}
      </div>
    </Modal>
  )
}

InviteUsers.propTypes = {
  show: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  analytics: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default InviteUsers
